<template>
    <el-table refs="historiesTable" row-key="id" header-row-class-name="thead-light" :height="autoHeight? 200 : 400" :class="tableClass" v-loading="loading"
        :data="tableData" stripe empty-text="没有修改记录">
        <el-table-column prop="total_amount" label="总额(万元)" width="110" class-name="pl-2">
        <template slot-scope="{row}">
            {{ row.total_amount ? parseFloat(row.total_amount).toFixed(2) : '0.00' }}
        </template>
        </el-table-column>
        <el-table-column prop="fupin_amount" label="扶贫额(万元)" width="110">
        <template slot-scope="{row}">
            {{ row.fupin_amount ? parseFloat(row.fupin_amount).toFixed(2) : '0.00' }}
        </template>
        </el-table-column>
        <el-table-column prop="created_at" label="修改时间" min-width="150">
            <template slot-scope="scope">
                {{ formatDateTime(scope.row.created_at) }}
            </template>
        </el-table-column>
        <el-table-column prop="who" label="操作员"></el-table-column>
    </el-table>
</template>
<script>
/* eslint-disable */ 
//import moment from "moment";

export default {
  props: {
    reportid: {
        type: Number,
        default: null
    },
    type: {
        type: String,
        description: "report type",
        default: null
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      tableData: []
    }
  },
  methods: {
    formatDateTime(datestr) {
      if(!datestr) return "";
      return moment(datestr).format("YYYY-MM-DD HH:mm")
    },
    formatInt2(num, length) {
        return num<10 ? (Array(length).join('0') + num).slice(-length) : num;
    },
    fetchData() {
      this.loading = true;
      this.axios.get("reportedithistories", { params: { type: this.type, reportId: this.reportid } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data;
        }
        else {
            this.$notifyErr('未能加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style>
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>